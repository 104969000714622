.footer{
    width: 100%;
    text-align: center;
    background-color: rgb(214, 214, 214);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.gt{
    color: inherit;
}
