.home {
    width: 100%;
}

.background{
    width: 100%;
    height: 100vh;
    background-image: url("../assets/tywy-brodown.jpg");
    background-size: cover; 
}

.pitt {
    color: white;
    font-size: 36px;
    font-weight: bold;    
    float: right;
    padding-right: 2.5rem;
}

.bitt-text{
    color: white;
    font-size: 20px;
    font-weight: normal;    
    position: absolute;
    right: 17.55em;
    padding-top: 2.5em;
    padding-right: 2em;
}

.bitt{
    width: 100px;
    position: absolute;
    right: 35em
}

.credits{
    color: rgba(255, 255, 255, 0.741);
    display: flex;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    bottom: 20px;
    right: 16px;
}

.credits a{
    color: inherit;
    text-decoration: none;
    text-align: right;
}


.button-container{
    width: 200px;
    height: 400px;
    overflow: hidden;
    display: flex;
    position: absolute;
    bottom: -3.1em;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: flex-end;
    /*border: red solid 1px;*/
}

.join-button{
    background: rgb(255, 255, 255);
    height: 40px;
    line-height: 20px;
    width: 150px;
    border: none;
    color: rgba(0, 80, 155, 0.67);
    padding: 0.5em 1em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 1em;
    font-size: 20px;
    font-weight: normal;
}

@media (max-width: 676px) {
    .pitt {display: none;}
    .bitt-text {display: none;}
    .bitt {display: none;}

    .background{
        background-position: center;
    }
}

