.card-container{
    height: 326px;
    width: 300px;
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #999;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    background-color: white;
}

.card-image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
}

.card-title {
    margin: 0.5 rem 10%;
    text-align: center;
    font-size: 19px;
}