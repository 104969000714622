.media{
    width: 100%;
}

.text1 {
    text-align: left;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.group{
    display: flex;
    width: 100%;
}

.video {
    width: 300%;
    float: left;
    margin-left: 30px;
    box-shadow: 0px 10px 8px #999;
}

.text3{
    float: right;
}

.text3 h2{
    margin-top: 2px;
    padding-left: 5%;
    padding-bottom: 15px;
}

.text3 p{
    width: 80%;
    padding-left: 5%;
    font-size: 16.1px;
}

.text2 {
    padding-top: 30px;
    padding-left: 30px;
}

.grid-container{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 30px;
    column-gap: 30px;
    row-gap: 40px;
    margin-bottom: 35px;
}

.grid-item{
    box-shadow: 0px 10px 8px #999;
}

@media (max-width: 1510px) {
        .video {
            box-shadow: none;
        }
}   

@media (max-width: 1469px) {
    .text3 p{
        width: 80%;
        padding-left: 5%;
        font-size: 14px;
    }
}  

@media (max-width: 1300px) {
    .text3 p{
        width: 80%;
        padding-left: 5%;
        font-size: 12px;
    }
}   

@media (max-width: 1143px) {
    .text3 p {display: none;}   
    .text3 h2 {display: none;}  
}

@media (max-width: 989px) {
    .grid-container{
        display: grid;
        grid-template-columns: auto auto;
        padding: 30px;
        column-gap: 30px;
        row-gap: 40px;
    }
    .video {
        width: 90%;
        float: left;
        margin: left;
        margin-left: 30px;
        box-shadow: 0px 10px 8px #999;
    }
}

@media (max-width: 793px) {
    .video {
        box-shadow: none;
        margin-bottom: 30px;
    }
}

@media (max-width: 700px) {
    .grid-container{
        display: grid;
        grid-template-columns: auto;
        padding: 30px;
        column-gap: 30px;
        row-gap: 40px;
    }
}